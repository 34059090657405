/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import {
  TitleDefault,
  TitleAlt,
  TitleSubDefault,
  BannerDefault,
  LineDefault,
  BoxDefault,
  ContentDefault,
  ButtonDefault,
} from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import GravityForm from 'components/GravityForm'
import Socials from 'components/Socials'

// Language
import { getLanguage } from 'services/language'

// CSS
import 'styles/templates/ContactTemplate.scss'

export const pageQuery = graphql`
  query ($wordpress_id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      acf {
        titleTop: title_top
        titleBottom: title_bottom
        titleAddressInfo: title_address_info
        titleSocialMedia: title_social_media
        buttonRoute: route_button

        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
    contactPageNL: wordpressPage(wordpress_id: { eq: 17 }) {
      acf {
        phonenumber
        streetname
        housenumber
        zipcode
        city
        country
        emailAddress: email_address
      }
    }
    contactPageEN: wordpressPage(wordpress_id: { eq: 72 }) {
      acf {
        phonenumber
        streetname
        housenumber
        zipcode
        city
        country
        emailAddress: email_address
      }
    }
  }
`

function Contact({ data: { page, contactPageNL, contactPageEN } }) {
  const {
    titleTop,
    titleBottom,
    titleAddressInfo,
    titleSocialMedia,
    buttonRoute,
  } = page.acf

  const language = getLanguage()

  const contactContent = language === 'nl_NL' ? contactPageNL : contactPageEN

  return (
    <Layout backgroundImage={page.acf.backgroundImage}>
      {page.acf.backgroundImage && page.acf.backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: page.yoast_meta }}
          pathname={page.path}
          image={page.acf.backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} />
      )}
      <div className="contact-template">
        <BannerDefault className="py-5">
          <div className="row">
            <div className="col-12 col-lg-6 py-4">
              <LineDefault />
              <TitleAlt className="mt-4 pl-lg-2">{titleTop}</TitleAlt>
              <TitleDefault asH1 className="pl-lg-5">
                {titleBottom}
              </TitleDefault>
            </div>
            <div className="col-12 col-lg-6" />
          </div>
        </BannerDefault>

        <div className="container pb-4">
          <div className="row">
            <div className="col-12 col-lg-7">
              <BoxDefault className="py-3 py-lg-4 px-3 px-lg-5">
                <GravityForm id={language === 'nl_NL' ? 1 : 2} />
              </BoxDefault>
            </div>
            <div className="col-12 col-lg-5 pl-lg-5 mt-5 mt-lg-0">
              <ContentDefault>
                <div>
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&destination=${contactContent.acf.streetname}+${contactContent.acf.housenumber},+${contactContent.acf.zipcode}+${contactContent.acf.city}&travelmode=driving`}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="w-100 ignore-element"
                  >
                    <ButtonDefault className="w-lg-100">
                      <div dangerouslySetInnerHTML={{ __html: buttonRoute }} />
                    </ButtonDefault>
                  </a>
                </div>
                <div className="mt-5">
                  <TitleSubDefault>{titleAddressInfo}</TitleSubDefault>
                  <ul className="mt-3">
                    <li>
                      {contactContent.acf.streetname}{' '}
                      {contactContent.acf.housenumber}
                      <br />
                      {contactContent.acf.zipcode} {contactContent.acf.city}
                      <br />
                      {contactContent.acf.country}
                    </li>
                    <li>
                      <a
                        href={`tel:${contactContent.acf.phonenumber}`}
                        className="ignore-element"
                      >
                        {contactContent.acf.phonenumber}
                      </a>
                    </li>
                    <li>
                      <a
                        href={`mailto:${contactContent.acf.emailAddress}`}
                        className="ignore-element"
                      >
                        {contactContent.acf.emailAddress}
                      </a>
                    </li>
                  </ul>
                </div>
              </ContentDefault>

              <div className="mt-5">
                <TitleSubDefault>{titleSocialMedia}</TitleSubDefault>
                <div className="mt-3">
                  <Socials />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pb-5 px-lg-5">
          <BoxDefault className="contact-template-map my-5 mx-4">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2461.2641727053037!2d4.497053116265279!3d51.91089118894125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c433132e6d3cc7%3A0xc7c20e574032ef0d!2sMezzami%20B.V.%20(PBXcomplete%20%2F%20VOIPcomplete)!5e0!3m2!1snl!2snl!4v1578650860786!5m2!1snl!2snl"
              width="100%"
              height="450"
              frameBorder="0"
              allowFullScreen=""
              title="Google Maps"
            />
          </BoxDefault>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
